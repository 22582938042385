.App {
  text-align: center;
  background: linear-gradient(to right, #74DD83,  #7494DF);
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.Kosh-screenshot {
  height: 80vmin;
  padding-right: 50px;
  animation: Kosh-screenshot-slide 1.25s ease-out;
}

.AppStoreBadge {
  width: 300px;
  animation: Kosh-text-appearance 3s;
}

.Kosh-textbox {
  padding-left: 50px;
}

.Kosh-title {
  animation: Kosh-text-appearance 3s;
  font-size: calc(10px + 4vmin);
  color: rgb(200,200,200);
  font-weight: bold;
}

.Kosh-description {
  animation: Kosh-text-appearance 3s;
  font-size: calc(10px + 2vmin);
  color: rgb(100,100,100);
}

.Left-container {
  width: 50%;
  text-align: right;
}

.Right-container {
  width: 50%;
  text-align: left;
}

.MainContent {
  align-items: center;
  display: flex;
}

@keyframes Kosh-screenshot-slide {
  from {
    transform: translateY(30vh);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes Kosh-text-appearance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
