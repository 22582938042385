.footer {
  margin-top: 1rem;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.LinkStyle {
  color: black;
  text-decoration: none;
}
